$env: stage;
@import '@/styles/_component-base.styles.scss';

.title {
    text-align: center;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: spacer(lg);
    font-size: set-fontSize(24);
    font-family: $fontFamily-serif;
    color: color(text, headingDark);

    @include breakpoint(md) {
        font-size: set-fontSize(28);
    }

    @include breakpoint(lg) {
        font-size: set-fontSize(32);
        margin-bottom: spacer(2dot5xl);
    }
}

.tile {
    margin-bottom: spacer(md);

    &:hover {
        .image {
            transform: scale(1.04);
            box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 30px;
        }

        .name {
            color: color(text, base);
            text-decoration: underline;
        }
    }

    @include breakpoint(md) {
        margin-bottom: spacer(2dot5xl);
    }
}

.image {
    width: 155px;
    height: 155px;
    transition: transform .2s;

    @include breakpoint(md) {
        width: 210px;
        height: 210px;
    }

        @include breakpoint(lg) {
        width: 294px;
        height: 294px;
    }

    @include breakpoint(xl) {
        width: 349px;
        height: 349px;
    }
}

.name {
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    margin-top: spacer(xs);
    text-transform: uppercase;
    font-size: set-fontSize(16);
    color: color(text, headingDark);
    font-family: $fontFamily-sans-serif;

    @include breakpoint(md) {
        font-size: set-fontSize(18);
    }

    @include breakpoint(lg) {
        padding: 0 spacer(3xl);
        margin-top: spacer(base);
        font-size: set-fontSize(20);
    }
}
