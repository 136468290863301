$env: stage;
@import '@/styles/_component-base.styles.scss';

/// ---- LEGACY START --------
.whySaatva {
    padding: spacer(2dot5xl) 0;
    background-color: color(contrast, 1);

    &Title{
        color: color(text, headingDark);
    }
}

.tile {
    margin-bottom: spacer(lg);

    @include breakpoint(md) {
        margin-bottom: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.row {
    @include only-breakpoint(md) {
        align-content: flex-start;
    }
}

.headingWrapper {
    display: flex;
    flex-direction: row;

    @include only-breakpoint(md) {
        align-items: flex-start;
        flex-direction: column;
    }
}

.iconWrapper {
    align-self: center;
    margin-right: spacer(md);

    @include only-breakpoint(md) {
        align-self: flex-start;
        margin-right: inherit;
    }
}

.svg {
    width: 100%;
    overflow: visible;
    margin: spacer(2xs) auto auto;
    width: 60px;
    height: 60px;
}


.title {
    display: flex;
    align-items: center;
    text-align: left;
    font-family: $fontFamily-serif;
    white-space: pre-line;
    font-size: set-fontSize(20);
    color: color(text, headingDark);

    &:visited {
        color: color(text, headingDark);
    }

    &:hover {
        color: color(nav, active);
        text-decoration: underline;
    }

    @include only-breakpoint(md) {
        white-space: normal;
        margin-top: spacer(base);
    }
}

.description {
    text-align: left;
    color: color(text, base);
    font-size: set-fontSize(14);
    word-wrap: break-word;

    @include breakpoint(lg) {
        font-size: set-fontSize(16);
    }
}

.cta {
    text-align: left;
    text-decoration: underline;
    font-weight: normal;
    font-size: set-fontSize(14);
    color: color(text, heading);

    &:visited {
        color: color(text, heading);
    }

    &:hover {
        color: color(nav, active);
        cursor: pointer;
    }

    @include breakpoint(lg) {
        font-size: set-fontSize(16);
    }
}

/// ----- LEGACY END -------

.whySaatvaV1 {
    background-color: color(contrast, white);

    .container {
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
    }

    .column {
        padding-top: spacer(3xs);

        @include breakpoint(md) {
            padding-right: spacer(3xs);
        }
    }

    .backgroundImage {
        height: 375px;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.35;
            background-color: rgba(0, 0, 0, 1);
        }

        @include breakpoint(md) {
            height: 385px;
        }

        @include breakpoint(lg) {
            height: 450px;
        }
    }

    .justifyContentCenter {
        justify-content: center;
    }

    .tile {
        &:hover, &:focus {
            .tileContentCta {
                background-color: rgba(0, 0, 0, 1);
            }
        }

        &Content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: spacer(lg) spacer(2xl);

            @include breakpoint(md) {
                padding: spacer(3xl);
            }

            color: color(contrast, white);

            h3 {
                font-weight: 400;
                line-height: 35px;
                text-align: center;
                font-size: set-fontSize(24);
                font-family: $fontFamily-serif;

                @include breakpoint(lg) {
                    font-size: set-fontSize(28);
                }
            }

            p {
                max-width: 600px;
                font-weight: 400;
                line-height: 28px;
                text-align: center;
                font-size: set-fontSize(16);
                margin-bottom: spacer(base);
                font-family: $fontFamily-sans-serif;

                @include breakpoint(md) {
                    margin-bottom: spacer(lg);
                }
            }

            &Cta {
                font-weight: 400;
                font-size: set-fontSize(16);
                color: color(contrast, white);
                background-color: rgba(0, 0, 0, 0.35);
                border: color(contrast, white) 1px solid;
            }
        }
    }
}
